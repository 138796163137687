import { default as _91token_93lEnwVwuiakMeta } from "/var/www/sites/auth.staging.ultrafedt.dk/releases/20250124223529/pages/activate/[token].vue?macro=true";
import { default as indexps4YJcmPJBMeta } from "/var/www/sites/auth.staging.ultrafedt.dk/releases/20250124223529/pages/index.vue?macro=true";
import { default as _91token_93UoBey2IItXMeta } from "/var/www/sites/auth.staging.ultrafedt.dk/releases/20250124223529/pages/password/reset/[token].vue?macro=true";
import { default as indexi25oVNpcbBMeta } from "/var/www/sites/auth.staging.ultrafedt.dk/releases/20250124223529/pages/password/reset/index.vue?macro=true";
import { default as redirectwLdOvgTqOdMeta } from "/var/www/sites/auth.staging.ultrafedt.dk/releases/20250124223529/pages/redirect.vue?macro=true";
export default [
  {
    name: "activate-token",
    path: "/activate/:token()",
    component: () => import("/var/www/sites/auth.staging.ultrafedt.dk/releases/20250124223529/pages/activate/[token].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/auth.staging.ultrafedt.dk/releases/20250124223529/pages/index.vue")
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    component: () => import("/var/www/sites/auth.staging.ultrafedt.dk/releases/20250124223529/pages/password/reset/[token].vue")
  },
  {
    name: "password-reset",
    path: "/password/reset",
    component: () => import("/var/www/sites/auth.staging.ultrafedt.dk/releases/20250124223529/pages/password/reset/index.vue")
  },
  {
    name: "redirect",
    path: "/redirect",
    component: () => import("/var/www/sites/auth.staging.ultrafedt.dk/releases/20250124223529/pages/redirect.vue")
  }
]